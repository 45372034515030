@import 'variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-body;
}

body, html {
  overflow-x: hidden;
}

.font__alternate, h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-headings;
}

h1 {
  font-size: 4em;
}

body, .application__container {
  background-color: $fc-dark-grey;
}

footer {
  margin-top: 200px;
  font-size: .5em;
  text-align: center;
  color: white;
}
