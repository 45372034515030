@import '../variables.scss';

.cover-section--center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 450px;
  justify-content: center;

  .cs-kettle, .cs-v60, .cs-phone {
    display: block;
  }

  .cs-kettle {
    width: 300px;
    transform: translateX(-50%);
  }
  .cs-v60 {
    width: 200px;
    transform: translateX(5%);
  }
  .cs-phone {
    width: 200px;
  }

  .cs-image-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50vw;
  }

  .cs-word-group {
    color: $fc-green;
    max-width: 50vw;
    padding: 1em 2em;
    text-align: right;

    &.cs-word-group--clients {
      text-align: center;
      width: 100%;

      ul {
        list-style: none;
        padding: 0;
      }
    }

    p {
      font-size: 1.5em;
      @media all and (max-width: 800px) {
        font-size: 1em;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }

  @media all and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    
    .cs-word-group {
      text-align: center;      
    }

    .cs-kettle {
      width: 150px;
    }
    .cs-v60 {
      width: 100px;
    }
    .cs-phone {
      width: 100px;
    }
  }
}