@import '../variables.scss';
@import '../table.scss';

.application__container {
  a {
    color: $fc-green;
    text-decoration: none;

    &:hover {
      $lighter-green: lighten($fc-green, 20);
      color: $lighter-green;
      border-bottom: 1px solid $lighter-green;
    }
  }

  .cover-section.cover-section--overlay-video {
    background-color: white;
    min-height: 100vh;
    width: 100%;
    mask-image: url("../img/drip2.svg");
    mask-position: center bottom;
    mask-repeat: no-repeat;
    mask-size: cover;
    margin-bottom: -200px;
    padding-top: 200px;
  }

  .video-container {
    z-index: 1;
    width: 100%;
    height: 120vh;
    min-height: 900px;
    overflow: hidden;
    position: relative;
    mask-image: url("../img/drip2.svg");
    mask-position: center bottom;
    mask-repeat: no-repeat;
    mask-size: cover;
    margin-bottom: -200px;

    .video-content {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding-bottom: 150px;
      padding-left: 2em;
      padding-right: 2em;

      @media all and (max-width: 700px) {
        .menuboard {
          height: 250px !important;
        }
      }

    }

    .cover-section {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: $fc-green;
      z-index: 2;

      @media all and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }

      p {
        font-size: 1.5em;
        @media all and (max-width: 900px) {
          font-size: 1em;
        }
      }
    }

    .video-screen {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $fc-dark-grey;
      opacity: .8;
    }
  }

  .cover-section--bottom {
    margin-top: 200px;
    color: $fc-green;

    text-align: center;
    padding: 0 2em;
  }

  > nav {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: .5em 2em;
  }

 .fc__logo {
    width: 250px;

    img {
      height: 100px;
    }
  }

  .nav-menu {
    list-style: none;
    display: flex;

    a {
      margin: 0 1em;
      color: $fc-green;
      font-family: $font-family-headings;
      text-decoration: none;
      font-size: 1.4em;

      &:hover {
        $lighter-green: lighten($fc-green, 20);
        // border-bottom-style: groove;
        color: $lighter-green;
        // border-bottom-style: ;
        border-bottom: 1px solid $lighter-green;
      }
    }
  }
}


@media all and (max-width: 900px) {
  h1 {
    font-size: 2em !important;
  }
}

.sales-callout {
  padding: 2em 1em;
  font-size: 3em;

  @media all and (max-width: 700px) {
    padding: 1em 1em;
    font-size: 2em;
  }
}
