@import '../variables.scss';

.list-signup--bottom {
  margin: 3em auto;
  width: 50%;

  #mc_embed_signup_scroll {
    text-align: center !important;

    input {
      height: 50px;
    }
  }
}

#mc-embedded-subscribe-form {
  padding: 0 !important;

  > #mc_embed_signup_scroll {
    input[type="email"] {
      background-color: transparent;
      border: 1px solid $fc-green;
      color: white;

      &::placeholder {
        color: $fc-green;
        opacity: .8;
      }
    }

    input[type="submit"] {
      background: $fc-green;
      color: $fc-dark-grey;
      width: auto;
      padding: 0 1em;
    }

    input {
      display: inline-block;
    }
  }
}
